import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.containerTarget.innerHTML = this.thumbnail();
  }

  thumbnail() {
    return `<div class="group relative" data-controller="youtube" data-youtube-id="${this.data.get(
      "id"
    )}">
      <img src="https://i.ytimg.com/vi/${this.data.get(
        "id"
      )}/mqdefault.jpg" data-target="youtube.thumbnail" data-action="click->youtube#play">

      <div data-action="click->youtube#play" data-target="youtube.container" class="transition opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center px-4 absolute inset-0 w-full h-full cursor-pointer">
        <h4 class="text-2xl font-display leading-tight tracking-wide uppercase">
          ${this.data.get("title")}
        </h4>
        <h5 class="text-sm my-0 font-semibold leading-tight">
          ${this.data.get("subtitle")}
        </h5>
        <p class="text-sm font-semibold leading-tight">
          ${this.data.get("year")}
        </p>
      </div>
    </div>`;
  }
}
