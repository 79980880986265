import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["quotes"];

  load() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.quotesTarget.innerHTML = html;
        sr.sync();
      });
  }
}
