import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["div"];

  toggle() {
    this.divTarget.classList.toggle("hidden");
    this.triggerResize();
  }

  close() {
    this.divTarget.classList.add("hidden");
  }

  triggerResize() {
    var event;

    if (typeof Event === "function") {
      event = new Event("resize");
    } else {
      /*IE*/
      event = document.createEvent("Event");
      event.initEvent("resize", true, true);
    }

    window.dispatchEvent(event);
  }
}
