import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["line", "cursor"];

  connect() {
    var lines = [];

    this.lineTargets.forEach(line => {
      lines.push(line.textContent);
      line.textContent = "";
    });

    this.type(lines, 0);
  }

  type(lines, i) {
    if (!lines[i]) return;

    var string = lines[i];
    var str = string.split("");
    var that = this;

    this.cursorTargets[i].classList.remove("hidden");

    (function animate() {
      str.length > 0
        ? (that.lineTargets[i].textContent += str.shift())
        : clearTimeout(running);
      var running = setTimeout(animate, Math.ceil(Math.random() * 100) + 50);

      if (str.length == 0) {
        if (lines.length > i + 1) {
          that.cursorTargets[i].classList.add("hidden");
        }
        clearTimeout(running);
        that.type(lines, i + 1);
      }
    })();
  }
}
