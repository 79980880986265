import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body", "menu", "button", "bg"];

  toggle() {
    this.bodyTarget.classList.toggle("overflow-hidden");
    this.menuTarget.classList.toggle("hidden");
    this.bgTarget.classList.toggle("zoom");
    this.buttonTarget.classList.toggle("is-active");
  }
}
