import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"];

  slow(event) {
    event.preventDefault();

    document.querySelector(this.linkTarget.getAttribute("href")).scrollIntoView({
      behavior: 'smooth'
    });
  }
}
