import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["media", "pauseIcon", "playIcon"];

  pause() {
    this.mediaTarget.pause();

    this.playIconTarget.classList.toggle("hidden");
    this.pauseIconTarget.classList.toggle("hidden");
  }

  play() {
    this.mediaTarget.play();

    this.pauseIconTarget.classList.toggle("hidden");
    this.playIconTarget.classList.toggle("hidden");
  }
}
