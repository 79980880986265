import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label", "media", "unmuteIcon", "muteIcon", "replayIcon"];

  replay() {
    this.mediaTarget.currentTime = "0";
    this.mediaTarget.play();
  }

  volume() {
    if (this.mediaTarget.muted) {
      this.mediaTarget.muted = false;
    } else {
      this.mediaTarget.muted = true;
    }

    this.unmuteIconTarget.classList.toggle("hidden");
    this.muteIconTarget.classList.toggle("hidden");
  }
}
