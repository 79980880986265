import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "icon" ]

  toggle() {
    this.iconTarget.classList.toggle("text-white")
    this.iconTarget.classList.toggle("text-black")
    this.inputTarget.classList.toggle("hidden")
  }
}
