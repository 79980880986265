import { Controller } from "stimulus";
import ScrollReveal from "scrollreveal";

export default class extends Controller {
  connect() {
    window.sr = ScrollReveal();

    window.addEventListener("load", () => {
      sr.reveal(".sm-reveal", {
        distance: "16px",
        duration: 1000,
        easing: "cubic-bezier(0.405, 0.005, 0, 1)"
      });

      sr.reveal(".lg-reveal", {
        distance: "60px",
        duration: 1000,
        easing: "cubic-bezier(0.405, 0.005, 0, 1)"
      });


      sr.reveal('.quote-reveal', {
        distance: "16px",
        duration: 1000,
        interval: 2000,
        easing: "cubic-bezier(0.405, 0.005, 0, 1)"
      });
    });
  }
}
