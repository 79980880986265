import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["icon"];

  onScroll(event) {
    var scrollTop = $(window).scrollTop();
    var height = $(window).height() * .8;

    $(this.iconTarget).css({
      'opacity': ((height - scrollTop) / height)
    });
  }
}
