import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "thumbnail"];

  play() {
    var iframe = document.createElement("iframe");
    iframe.setAttribute(
      "src",
      `https://www.youtube-nocookie.com/embed/${this.data.get("id")}?autoplay=1`
    );
    iframe.setAttribute("width", "560");
    iframe.setAttribute("height", "315");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute(
      "allow",
      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    );
    iframe.setAttribute("allowfullscreen", "1");
    this.containerTarget.parentNode.replaceChild(iframe, this.containerTarget);
    this.thumbnailTarget.classList.add("invisible");
  }
}
